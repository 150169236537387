<template>
  <section class="main-tests">
    <div class="main-title">
      <h2>не знаете что вам подойдет?</h2>
    </div>

    <div class="main-tests__row">
      <div class="main-tests__info">
        <div class="main-tests__info_cheaps">
          <span>тест</span>
          <span>2 минуты</span>
          <span>6 вопросов</span>
        </div>

        <h4>подберем программу обучения на&nbsp;бесплатной консультации</h4>
        <p>
          оставьте заявку&nbsp;отделу заботы&nbsp;— мы&nbsp;поможем подобрать подходящее
          направление для&nbsp;ребенка, а&nbsp;также&nbsp;ответим на&nbsp;любые вопросы
        </p>

        <div class="main-tests__info_soc">
          <span>или напишите нам в чат</span>
          <div>
            <a
              href="https://wa.me/79686286327"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/wa.png"
                alt="whatsapp"
                loading="lazy"
              >
            </a>
            <a
              :href="$getTelegramLink($route)"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/tg.png"
                alt="telegram"
                loading="lazy"
              >
            </a>
            <a
              href="mailto:nstolbova@synergy.ru"
              target="_blank"
            >
              <img
                src="/v2/school-university/footer/email.png"
                alt="email"
                loading="lazy"
              >
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="currentQuestionIndex < questions.length"
        class="main-tests__test"
      >
        <p
          v-if="emptyError"
          class="main-tests__test_error"
        >
          *похоже вы ничего не отметили, выберите наиболее подходящее
        </p>
        <h5 v-html="currentQuestion.title" />
        <p class="main-tests__test_text">
          <img
            src="/v2/school-university/tests/accent.svg"
            alt="*"
            loading="lazy"
          >
          можно выбрать несколько ответов
        </p>
        <div class="main-tests__test_checkboxes">
          <label
            v-for="(checkbox, index) in currentQuestion.variants"
            :key="index"
          >
            <input
              v-model="currentQuestion.answers"
              type="checkbox"
              :value="checkbox.text"
            >
            <b class="main-tests__test_checkbox" />
            <span v-html="checkbox.text" />
          </label>
        </div>
        <button
          class="m-btn main-tests__test_btn"
          @click="nextQuestion"
        >
          далее
        </button>
      </div>

      <div
        v-show="currentQuestionIndex === questions.length && !testComplete"
        class="main-tests__test main-tests__form"
      >
        <h5>
          отдел заботы получил результаты и&nbsp;подобрал программу обучения
        </h5>
        <p class="main-tests__form_text">
          заполните форму, чтобы&nbsp;мы&nbsp;смогли с&nbsp;вами&nbsp;связаться
        </p>

        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="main-tests__form_field"
          placeholder="как вас зовут?"
          round-border
        />

        <form-phone-field
          ref="phoneField"
          class="main-tests__form_field"
          placeholder="ваш номер телефона"
          round-border
          @updatePhone="phoneInput = $event"
        />

        <span class="main-tests__form_privacy">
          нажимая на кнопку, вы соглашаетесь
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >условиями обработки данных</nuxt-link>
          в&nbsp;соответствии
          с&nbsp;<nuxt-link
            to="/privacy"
            target="_blank"
          >политикой конфиденциальности</nuxt-link>
        </span>

        <button
          class="m-btn main-tests__test_btn"
          @click="submit"
        >
          оставить заявку
        </button>
      </div>

      <div
        v-if="testComplete"
        class="main-tests__test main-tests__complete"
      >
        <h5>
          cпасибо! ваша заявка уже у нас
        </h5>
        <p class="main-tests__complete_text">
          специалист отдела заботы скоро свяжется с&nbsp;вами, а&nbsp;если удобнее
          общаться в&nbsp;мессенджере, то&nbsp;переходите в&nbsp;наш&nbsp;тг-бот
        </p>

        <button
          class="m-btn main-tests__complete_btn"
          @click="restartTest"
        >
          пройти еще раз
        </button>
        <a
          class="m-btn main-tests__complete_tg"
          :href="$getTelegramLink($route)"
          target="_blank"
        >
          <span>оставить заявку</span>
          <img
            src="/v2/main/tests/telegram.svg"
            alt="telegram"
            loading="lazy"
          >
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';
import FormNameField from '../common/fileds/FormNameField.vue';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needName: true,
});

export default {
  name: 'MainTests',
  components: { FormNameField, FormPhoneField },
  mixins: [sendLeadMixin],
  data: () => ({
    emptyError: false,
    currentQuestionIndex: 0,
    questions: [
      {
        title: '1. какая основная цель обучения?',
        variants: [
          { text: 'получить государственный аттестат' },
          { text: 'подготовиться к огэ/егэ/олимпиадам' },
          { text: 'подготовиться к школе' },
          { text: 'поступить в конкретный вуз/колледж' },
          { text: 'развитие навыков (it, программирование, творчество, бизнес, лидерство, креативность и др)' },
        ],
        answers: [],
      },
      {
        title: '2. какая форма обучения предпочтительнее?',
        variants: [
          { text: 'онлайн-школа' },
          { text: 'экстернат' },
          { text: 'очные занятия ' },
          { text: 'домашнее обучение' },
          { text: 'не определились' },
        ],
        answers: [],
      },
      {
        title: '3. сколько лет ребенку?',
        variants: [
          // eslint-disable-next-line max-len,vue/max-len
          { text: 'дошкольник (до 7 лет)' },
          { text: 'младшая школа (1-4 класс)' },
          { text: 'средняя школа (5-8 класс)' },
          { text: 'старшая школа (9-11 класс)' },
          { text: 'взрослый' },
        ],
        answers: [],
      },
      {
        title: '4. учились&nbsp;ли&nbsp;вы&nbsp;ранее в&nbsp;онлайн‑школе',
        variants: [
          { text: 'да' },
          { text: 'сейчас учусь' },
          { text: 'ходили только на онлайн курсы/репетиторство' },
          { text: 'нет' },
          { text: 'не планируем переходить в онлайн-школу' },
        ],
        answers: [],
      },
      {
        title: '5. есть ли у вас особые предпочтения?',
        variants: [
          { text: 'гибкий график' },
          { text: 'доступная цена' },
          { text: 'престижность программы' },
          { text: 'опытные педагоги' },
          { text: 'использование современных технологий' },
        ],
        answers: [],
      },
      {
        title: '6. что еще важно для вас?',
        variants: [
          { text: 'индивидуальный подход' },
          { text: 'контроль прогресса и результата' },
          { text: 'разнообразные учебные материалы' },
          { text: 'игровой формат обучения' },
          { text: 'поддержка по всем вопросам 24/7' },
        ],
        answers: [],
      },
    ],
    testComplete: false,
  }),
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIndex] || {};
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'currentQuestion.answers': function (answers) {
      if (answers?.length) {
        this.emptyError = false;
      }
    },
  },
  methods: {
    nextQuestion() {
      if (!this.currentQuestion.answers.length) {
        this.emptyError = true;
        return;
      }

      this.currentQuestionIndex += 1;
      this.emptyError = false;
    },
    onSubmit() {
      this.testComplete = true;
    },
    restartTest() {
      this.testComplete = false;
      this.emptyError = false;
      this.currentQuestionIndex = 0;
    },
  },
};
</script>

<style scoped lang="scss">

.main-tests {
  .main-tests__row {
    margin-top: calc(var(--scale) * 40);
    height: calc(var(--scale) * 720);
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 1.25rem / 2));
    gap: 1.25rem;

    @include media-down($size-tablet) {
      height: calc(var(--scale) * 512);
      grid-template-columns: repeat(2, calc(50% - 0.4rem));
      gap: 0.8rem;
    }

    @include media-down($size-mobile) {
      height: auto;
      grid-template-columns: 100%;
      grid-template-rows: calc(var(--scale) * 264) calc(var(--scale) * 512);
    }
  }

  .main-tests__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    background-color: #FFFFFF;
    border-radius: 3.75rem;
    padding: 2.5rem;

    @include media-down($size-tablet) {
      padding: 1.6rem;
      border-radius: 3.2rem;
    }

    &_cheaps {
      margin-bottom: 1rem;
      display: flex;
      gap: 0.25rem;

      @include media-down($size-tablet) {
        margin-bottom: 2rem;
      }

      span {
        padding: calc(var(--scale) * 10) 1.25rem;

        border: 1px solid #CACACB;
        border-radius: 2.5rem;

        font-weight: 500;
        font-size: 1rem;
        line-height: 130%;
        color: #02120F;

        @include media-down($size-tablet) {
          padding: 0.6rem 1.1rem;
          font-size: 1.4rem;
        }
      }
    }

    h4 {
      margin-bottom: 1.25rem;

      font-weight: 500;
      font-size: calc(var(--scale) * 64);
      line-height: 95%;
      letter-spacing: -0.02em;
      color: #02120F;

      @include media-down($size-tablet) {
        margin-bottom: 0.8rem;
        font-size: calc(var(--scale) * 28);
      }
    }

    p {
      max-width: calc(var(--scale) * 716);
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 110%;
      color: #A7ABAC;

      @include media-down($size-tablet) {
        font-size: 1.8rem;
      }
    }

    &_soc {
      margin-top: auto;

      @include media-down($size-tablet) {
        display: none;
      }

      span {
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 130%;
        color: #A7ABAC;
      }

      div {
        margin-top: calc(var(--scale) * 20);
        display: flex;
        gap: calc(var(--scale) * 8);

        img {
          width: calc(var(--scale) * 80);
        }
      }
    }
  }

  .main-tests__test {
    height: 100%;
    background-color: #0B72BE;
    background-image: url("/v2/main/tests/bg.webp");
    background-size: cover;
    border-radius: 3.75rem;
    padding: 2.5rem 2.35rem 2.5rem 2.5rem;
    position: relative;

    display: flex;
    flex-direction: column;

    @include media-down($size-tablet) {
      background-image: url("/v2/main/tests/bg-mob.webp");
      padding: 2rem 1.2rem 0.8rem 1.4rem;
      border-radius: 3.2rem;
    }

    h5 {
      height: calc(var(--scale) * 106);
      margin-bottom: calc(var(--scale) * 12);

      font-weight: 500;
      font-size: 3rem;
      line-height: 110%;
      letter-spacing: -0.02em;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        height: auto;
        margin-bottom: 3.8rem;
        font-size: 2rem;
      }
    }

    .main-tests__test_text {
      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: 1.8rem;
      }
    }

    .main-tests__test_checkboxes {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 16);
      }

      label {
        display: flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;

        @include media-down($size-tablet) {
          gap: 0.8rem;
          white-space: inherit;
          min-height: calc(var(--scale) * 43.5);
        }

        @media (hover: hover) {
          &:hover {
            .main-tests__test_checkbox {
              background-image: url("/v2/school-university/tests/checkbox-hover.svg");
            }

            input:checked + .main-tests__test_checkbox {
              background-image: url("/v2/school-university/tests/checkbox-active-hover.svg");
            }
          }
        }
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: calc(var(--scale) * 26);
        line-height: 120%;
        color: #FFFFFF;

        @include media-down($size-tablet) {
          font-weight: 400;
          font-size: calc(var(--scale) * 16);
          letter-spacing: -0.02em;
        }
      }

      input {
        display: none;
      }

      .main-tests__test_checkbox {
        flex-shrink: 0;
        width: 2.75rem;
        aspect-ratio: 1 / 1;

        background-image: url("/v2/school-university/tests/checkbox.svg");
        background-size: contain;
        background-repeat: no-repeat;

        @include media-down($size-tablet) {
          width: 3.2rem;
        }
      }

      input:checked + .main-tests__test_checkbox {
        background-image: url("/v2/school-university/tests/checkbox-active.svg");
      }
    }

    .main-tests__test_error {
      position: absolute;
      left: 2.5rem;
      top: 5.5rem;
      margin-top: 1.25rem;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #ED131C;

      @include media-down($size-tablet) {
        left: 1.5rem;
        top: 3rem;
        font-size: 1.4rem;
      }
    }

    .main-tests__test_btn {
      margin-top: auto;
      width: 100%;
      padding: 2.75rem 3.5rem;
      background-color: #FF5319;
      border-radius: 2.5rem;
      transition: 300ms;

      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        width: calc(100% + 1.6rem);
        margin-left: -0.8rem;
        padding: 3rem;
        box-shadow: 1rem 1rem 5rem rgba(7, 21, 57, 0.1), 0.4rem 0.4rem 2rem rgba(7, 21, 57, 0.05);
        font-size: 1.8rem;
      }

      @media (hover: hover) {
        &:hover {
          background-color: #FF9C12;
        }
      }
    }
  }

  .main-tests__form {
    h5 {
      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 28);
        margin-bottom: calc(var(--scale) * 8);
      }
    }

    &_text {
      margin-bottom: calc(var(--scale) * 54);

      font-style: normal;
      font-weight: 500;
      font-size: calc(var(--scale) * 26);
      line-height: 120%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: 1.6rem;
        margin-bottom: calc(var(--scale) * 20);
      }
    }

    &_field {
      margin-bottom: 0.5rem;

      @include media-down($size-tablet) {
        margin-bottom: 0.8rem;
      }

      ::v-deep input,
      ::v-deep .phone-field__wrap {
        width: 100%;
        border: none;
        height: calc(var(--scale) * 88);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 68);
        }

        input {
          font-size: calc(var(--scale) * 26);

          @include media-down($size-tablet) {
            font-size: 1.8rem;
            font-weight: 500;
          }
        }

        &--error {
          .phone-field__error {
            display: none;
          }

          input {
            border: 1px solid #ED131C;
          }
        }
      }
    }

    &_privacy {
      display: block;
      margin-top: 2.25rem;
      margin-bottom: 2rem;

      font-style: normal;
      font-weight: 400;
      font-size: 1.25rem;
      line-height: 130%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        margin-top: calc(var(--scale) * 12);
        font-size: 1.4rem;
      }

      a {
        color: #0A4183;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .main-tests__complete {
    h5 {
      height: auto;
      font-weight: 500;
      font-size: calc(var(--scale) * 112);
      line-height: 90%;
      letter-spacing: -0.06em;

      @include media-down($size-tablet) {
        margin-bottom: calc(var(--scale) * 8);
        font-size: calc(var(--scale) * 40);
        line-height: 110%;
        letter-spacing: -0.04em;
      }
    }

    .main-tests__complete_text {
      font-weight: 500;
      font-size: calc(var(--scale) * 32);
      line-height: 110%;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-size: calc(var(--scale) * 18);
        line-height: 120%;
      }
    }

    .main-tests__complete_btn {
      margin-top: auto;
      margin-bottom: calc(var(--scale) * 20);
      font-weight: 400;
      font-size: calc(var(--scale) * 20);
      line-height: 130%;
      text-decoration-line: underline;
      color: #FFFFFF;

      @include media-down($size-tablet) {
        font-weight: 500;
        font-size: calc(var(--scale) * 14);
      }
    }

    .main-tests__complete_tg {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: calc(var(--scale) * 12);
      width: 100%;
      height: calc(var(--scale) * 122);
      padding: 1rem;
      background-color: #FFFFFF;
      border-radius: 2.5rem;
      transition: 300ms;

      font-weight: 500;
      color: #2193CC;
      font-size: 2rem;
      line-height: 110%;

      @include media-down($size-tablet) {
        width: calc(100% + 1.6rem);
        height: calc(var(--scale) * 80);
        margin-left: -0.8rem;
        padding: 3rem;
        box-shadow: 1rem 1rem 5rem rgba(7, 21, 57, 0.1), 0.4rem 0.4rem 2rem rgba(7, 21, 57, 0.05);
        font-size: 1.8rem;
      }

      img {
        width: calc(var(--scale) * 44);
        height: calc(var(--scale) * 44);

        @include media-down($size-tablet) {
          width: calc(var(--scale) * 32);
          height: calc(var(--scale) * 32);
        }
      }
    }
  }
}

</style>
